.registerScreen {
  .form {
    .k-form-fieldset {
      border: none !important;
    }
    max-width: 500px;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
      padding: 2%;
    }
  }
}
