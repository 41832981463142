.loginScreen {
  .form {
    max-width: 500px;
    margin: 0 auto;
  }
  .k-form-fieldset {
    border: none;
  }
  @media screen and (max-width: 768px) {
    padding: 2%;
  }
}
