.profileScreen {
  max-width: 500px;
  margin: 0 auto;
  h3 {
    color: #0076ad;
    margin: 16px 0;
    text-align: center;
  }
  p {
    margin: 8px 0;
  }
  a {
    text-decoration: none;
    color: #0076ad;
  }
  .form {
    .k-form-fieldset {
      border: none !important;
    }
    max-width: 500px;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
      padding: 2%;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 2%;
  }
}
