.alert {
  position: absolute;
  z-index: 5;
  margin-top: 80px;
  right: 0;
  margin-right: 10px;

  .toast-body {
    padding: 0;

    p {
      padding: 0.75rem;
    }
    .bar {
      height: 0.3rem;
      width: 100%;
      .in {
        animation: fill 5s linear 1;
        height: 100%;
        background-color: #007bff;
      }
    }

    @keyframes fill {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }
  }
}
