.section-template {
  max-width: 90%;
  margin: 0 auto;

  .table-input {
    background-color: transparent !important;
    border: none !important;
  }

  .fixHeader {
    .k-grid-header {
      position: fixed;
      top: 58px;
      z-index: 99999;
      max-width: 89%;
    }
  }
}
