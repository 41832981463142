// Import Bootstrap and its default variables
@import './pages/loginscreen.scss';
@import './pages/logoutscreen.scss';
@import './pages/registerscreen.scss';
@import './pages/profilescreen.scss';
@import './pages/homescreen.scss';
@import './pages/section.scss';
@import './components/navbar.scss';
@import './components/alert.scss';

@import './components/fields/textArea.scss';

@import url('https://fonts.googleapis.com/css?family=Roboto');
body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.k-animation-container {
  z-index: 10003; // set the desired value based on the application requirements
}
