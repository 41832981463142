.navbar-expand-lg {
  padding-left: 5% !important;
  padding-right: 5% !important;
  @media screen and (min-width: 768px) {
  }
}
.languageSwitcher {
  max-width: fit-content;
}
.signOut {
  max-width: fit-content;
}
.sections {
  max-width: fit-content;
}

#navbar-container {
  @media screen and (max-width: 992px) {
    justify-content: flex-end;
  }
}
