.logoutScreen {
  max-width: 500px;
  margin: 0 auto;
  h3 {
    color: #0076ad;
    margin: 16px 0;
  }
  p {
    margin: 8px 0;
  }
  a {
    text-decoration: none;
    color: #0076ad;
  }

  @media screen and (max-width: 768px) {
    padding: 2%;
  }
}
